.rail {
    left: 0px;
    bottom: 0px;
}

.railthumb {
    left: 0px;
    width: 0px;
}

.railright {
    top: 0px;
    right: 0px;
}

.railrightthumb {
    top: 0px;
    height: 0px;
}

.sticky-margin {
    margin-bottom: -74px;
}

.side-menu-margin {
    margin-right: 0px;
}

.jump-padding {
    padding-top: 74px;
}
.scrollablenav {
    overflow-y: auto !important;
}


.scrollablenav ::-webkit-scrollbar {
    width: 7px;
    overflow-y: auto !important;
  }
.scrollablenav ::-webkit-scrollbar-thumb {
  
    box-shadow: inset 11px 11px 11px #83c2dd !important;  
    border-radius: 10px !important;
}
  .scrollablenav::-webkit-scrollbar {      
    border-radius: 6px !important;

}

.scrollablenav::-webkit-scrollbar-thumb {
    box-shadow: inset 11px 11px 11px #83c2dd !important; 
    border-radius: 10px !important;
}