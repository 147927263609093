.prev-margin {
    margin: -35px 0 0 0;
}


.mr-2 {
    margin-right: 1em;
}

.chip, .singleChip {
    white-space: unset;
  }

  
  .builder-progress-bar {
    list-style: none;
  }
  .builder-progress-bar li {
    display: flex;
    position: relative;
    padding: 0.65rem 0;
  }
  .builder-progress-bar li:not(:last-child)::before{
    position: absolute;
    content: "";
    height: 100%;
    width: 5px;
    background-color: #f5f5f5;
    /* left: 3.5%; */
    transform: translateX(250%);
  }
  .builder-progress-bar li span {
    display: inline-block;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background-color: #f8eeeb;
    margin-right: 1rem;
    position: relative;
  }

  .builder-progress-bar li span::before {
    content: "";
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #b4bdce;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .builder-progress-bar li.active span::before {
    background-color: #13aeeb;
  }
  .builder-progress-bar li.active a{
    color: #13aeeb;
  }
  .builder-progress-bar li a {
    color: #76839a;
    font-size: 13px;
    font-weight: bold;
  }
/* step forem */


/* Profile builder Tab First time sign In or Not filled candidate a profile 
builder pages showing red color dot
Start code for Desktop View
*/
.sw-theme-dots>ul.step-anchor>li.active>.flagRed:after {
  background: #eb1339;
}

.sw-theme-dots>ul.step-anchor>li>.flagBlue:after {
  content: " ";
  position: relative;
  left: 36%;
  bottom: 5px;
  margin-top: 24px;
  display: block;
  width: 16px;
  height: 16px;
  background: #337166;
  border-radius: 50%;
  z-index: 99;
}

 
/* .sw-theme-dots>ul.step-anchor>li>.flagLink:after {
  content: " ";
  position: relative;
  left: 36%;
  bottom: 5px;
  margin-top: 24px;
  display: block;
  width: 16px;
  height: 16px;
  background: #eb1339;
  border-radius: 50%;
  z-index: 99;
} */

@keyframes blinkGlow {
  0% {
    opacity: 0;
    box-shadow: 0 0 3px 2px #eb1339;
  }
  50% {
    opacity: 1;
    box-shadow: 0 0 6px 3px #eb1339;
  }
  100% {
    opacity: 0;
    box-shadow: 0 0 3px 2px #eb1339;
  }
}

.sw-theme-dots>ul.step-anchor>li>.flagLink:after {
  content: " ";
  position: relative;
  left: 36%;
  bottom: 5px;
  margin-top: 24px;
  display: block;
  width: 16px;
  height: 16px;
  background: #eb1339;
  border-radius: 50%;
  z-index: 99;
  box-shadow: 0 0 2px 1px #eb1339;
}

.sw-theme-dots>ul.step-anchor>li.active>.flagRed {
  color: #eb1339;
}

.sw-theme-dots > ul.step-anchor > li.active:hover > .flagRed {
  color: #eb1339;
}

.sw-theme-dots .nav-tabs .flagRed:hover:not(.disabled) {
  color: #eb1339;
}

.sw-theme-dots>ul.step-anchor>li.active>.flagBlue:after {
  background: #337166;
}

.sw-theme-dots .nav-tabs .flagBlue:hover:not(.disabled) {
  color: #337166;
}

.sw-theme-dots>ul.step-anchor>li.active>.flagBlue {
  color: #337166;
}


/* Profile builder Tab First time sign In or Not filled candidate a profile 
builder pages showing red color dot
End code
*/

/* Profile builder Tab First time sign In or Not filled candidate a profile 
builder pages showing red color dot
Start code for Mobile View
*/ 
.builder-progress-bar li.active .flagRed::before {
  background-color: #eb1a13;
}

.builder-progress-bar li .flagRed::before {
  content: "";
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #f83606;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 2px 1px #eb1339;
}

.builder-progress-bar li .flagBlue::before {
  content: "";
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #337166;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.builder-progress-bar li.active .flagBlue::before {
  background-color: #337166;
}

.builder-progress-bar li.active .flagGreen {
  color: #337166;
}

.builder-progress-bar li.active .flagRedActive {
  color: #eb1a13;
}

/* Profile builder Tab First time sign In or Not filled candidate a profile 
builder pages showing red color dot
End code for Mobile View
*/