.apply-button {
  float: right;
  text-align: right;
}
.date-time-field {
  width: 17%;
}
input[type="datetime-local"]::-webkit-inner-spin-button,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.button-margin {
  margin-right: 5px;
}
/* .message-overlay {
  position: absolute;
  left: 70%;
  width: 75% !important;
  bottom: 40px;
  overflow-y: scroll !important;
} */

.message-position {
  /* position: fixed; */
  /* height: 500px;  */
  /* width: 900px; */
  /* right: -35%; */
  /* top: 25%; */
  z-index: 999999!important;
 }