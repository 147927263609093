.bold-text{
    font-weight: bold;
}
.pagination-panel {
    width: 100%;
    text-align: right;
    font-size: 18px;
}
.pagination-panel-margin {
    margin-top: 10px;
}
.arrow-margin{
    margin-left: 10px;
}
.pagination-panel-top {
    margin-bottom: 10px;
}
.pagination-panel-top select {
    border-radius: 7px;
    height: 40px;
    width: 60px
}
.customHeaderMenuButton {
    float: left;
    margin: 0 0 0 3px;
}

.customHeaderLabel {
    float: left;
    margin-right: 5px;
}

.customSortDownLabel {
    float: left;
    margin: 0 0 0 3px;
}

.customSortUpLabel {
    float: left;
    margin: 0;
}

.customSortRemoveLabel {
    float: left;
    margin: 0 0 0 3px;
    font-size: 14px;
}
.active {
    color: cornflowerblue;
}
.icon-align {
    text-align: left;
    padding-left: 10px;
}
.green-icon {
    font-size: 20px;
    color: green;
}
.grey-icon {
    font-size: 20px;
    color: darkgrey;
}




.ag-body-viewport-wrapper.ag-layout-normal {
    overflow-x: scroll !important;
    overflow-y: scroll !important;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none !important;
    width: 8px !important;
    height: 8px !important;
  }


  body *:hover::-webkit-scrollbar-thumb {
    background: #13AEEB;
  }
    body *::-webkit-scrollbar-thumb {
    background: #13AEEB;
  }

