.circular-progress {
    margin-left: 33%;
    width: 30%;
    padding-bottom: 2rem;
    padding-top: 1rem;
}

.circular-progress .chart-circle-value-3 {
    top: 0px !important;
}

.cfooter {
    position: absolute;
    bottom: 15px;
    width: 83%;
}
