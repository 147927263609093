.approved-color {
    color: green;
}
.rejected-color{
    color: red;
}
.lightgreen{
    color: lightgreen;
}
.indianred{
    color: indianred;
}


/* .emp_status .ag-center-cols-container{
    width: 100% !important;
} */