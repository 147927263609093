.deleteIconMargin {
    display: flex;
    align-items: center;
}

.pl-2 {
    padding-left: 10px;
}

.gender_hide_close .singleChip > img  {
    display : none;
}

.relationShip .singleChip > img  {
    display : none;
}

.refereneID .singleChip > img  {
    display : none;
}