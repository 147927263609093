.autocomplete-items {
    border-bottom: 1px solid #d4d4d4;
    overflow-y: auto;
    min-height: auto;
    max-height: 200px;
}

.autocomplete-items li {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
}

.autocomplete-items li:hover {
    background-color: #e9e9e9;
}

.autocomplete-items::-webkit-scrollbar-thumb {
    background-color: #8bb988 !important;
    border-radius: 5px;
}

.autocomplete-items::-webkit-scrollbar {
    width: 7px;
  }
.autocomplete-items ::-webkit-scrollbar-thumb {
    background-color: #8bb988 !important;
    border-radius: 5px;
}
