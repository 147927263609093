.display-block {
    display: block !important;
}

.tag {
    font-size: 10px;
    padding: .3em .4em .4em;
    margin: 0 .1em;
    margin-top: 3px;
    margin-left: 3px;
  }
  .tag a {
    color: #bbb;
    cursor: pointer;
    opacity: 0.6;
  }
  .tag a:hover {
    opacity: 1.0
  }
  .tag .remove {
    vertical-align: bottom;
    top: 0;
  }
  .tag a {
    margin: 0 0 0 .3em;
  }
  .tag a .glyphicon-white {
    color: #fff;
    margin-bottom: 4px;
    margin-left: 5px;
  }

  .resume-link {
    word-break: break-all;
  }

  .sw-theme-dots .step-content {
    padding: 15px 0;
    padding-top: 15px;
}

.chip, .singleChip {
  white-space: unset;
}

@media only screen and (max-width: 700px) {
  .mobile-view {
   display: block;
  }
  .desktop-view {
    display: none;
  }
}

@media only screen and (min-width: 700px) {
  .mobile-view {
   display: none;
  }
  .desktop-view {
    display: block;
  }
}